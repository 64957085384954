<template>
  <froala ref="editor" :tag="'textarea'" :config="froalaConfig" v-model="inputVal"></froala>
</template>

<script>
import {media} from "@/api";

export default {
  props: {
    value: { type: String },
    notAllowMoreRich: { type: Array, default: () => [] }
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        return this.$emit('input', val)
      },
    },

    froalaConfig() {
      let config = this.config;

      config.toolbarButtons.moreRich.buttons = config.toolbarButtons.moreRich.buttons
        .filter((value) => !this.notAllowMoreRich.includes(value))

      return config;
    }
  },

  data() {
    return {
      config: {
        key: 'pe1G2wD1I1A3B1E6A6njH-9i1aB-21kqF3usaG3C2A5D5C4E3D2D4D2E2==',
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting',
            ],
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'insertVideo',
              'insertTable',
              'emoticons',
              'fontAwesome',
              'specialCharacters',
              'embedly',
              'insertFile',
              'insertHR',
            ],
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2,
          },
        },
        events: {
          'image.beforeUpload': (images) => {
            if (!this.notAllowMoreRich.includes('insertImage')) {
              this.uploadImages(images);
            }

            return false;
          }
        }
      },
    }
  },

  methods: {
    uploadImages(images) {
      media.create('description', Array.from(images), true).then(({data}) => {
        const editor = this.$refs.editor.getEditor();

        editor.image.insert(data.url, false, null, editor.image.get());
      })
    }
  }
}
</script>
