<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">CSV Codes Batch <small>all listings.</small></h1>
      </b-col>

      <b-col sm="auto" class="ml-auto">
        <b-button :disabled="$cannot('supplier.manage')" v-b-modal.newCSVCodeBatch> New Batch </b-button>

        <b-modal
          title="New Code Batch"
          id="newCSVCodeBatch"
          hide-footer
          v-slot:default="{ close }"
          :no-close-on-backdrop="true"
        >
          <csv-codes-batch-form @close="refresh(close)" />
        </b-modal>
      </b-col>
    </b-row>

    <!-- page content -->
    <b-card>
      <b-form-input
        v-model="variation_filter"
        @input="loadContent"
        placeholder="Search by variation name"
        class="mb-4"
      />
        <div class="row">
            <div class="col-md-1">
                <b-form-group label="Show expired">
                    <b-form-checkbox v-model="expired" @change="loadContent" />
                </b-form-group>
            </div>
            <div class="col-md-1">
                <b-form-group label="Show used">
                    <b-form-checkbox v-model="used" @change="loadContent" />
                </b-form-group>
            </div>
        </div>


      <b-table :fields="fields" :items="listing" show-empty stacked="sm" bordered hover id="csv-codes-batch-table">
        <template #cell(product_variation)="data">
          <span v-html="data.value"></span>
        </template>
        <template #cell(supplier)="data">
          <span v-html="data.value"></span>
        </template>
        <template #cell(place)="data">
          <span v-html="data.value"></span>
        </template>
        <template v-slot:cell(actions)="row">
            <b-button class="btn-block" variant="default" size="sm" v-b-modal="`editCSVCodeBatch-${row.item.id}`"> View </b-button>
            <b-modal title="Edit Batch Details" :id="`editCSVCodeBatch-${row.item.id}`" hide-footer no-close-on-backdrop>
                <csv-codes-batch-edit-form :batch="row" @close="refresh(close)" />
            </b-modal>

            <div v-if="row.item.deleted_at">
                <small class="text-danger text-center">Deleted</small>
            </div>
            <div v-else>
                <b-button class="btn-block" variant="danger" size="sm" v-b-modal="`deleteCSVCodeBatch-${row.item.id}`"> Delete </b-button>
                <b-modal :id="`deleteCSVCodeBatch-${row.item.id}`" hide-footer no-close-on-backdrop>
                    <validation-observer v-slot="{ invalid }">
                        <form @submit.prevent="deleteBatch(row.item.id)">
                            <h5 v-if="row.item.used_codes > 0" class="text-center">
                                This batch carries used codes ({{ row.item.used_codes }} codes), are you sure you want to delete this batch #{{ row.item.id }}?
                            </h5>
                            <h5 v-else class="text-center">Are you sure you want to delete batch: #{{ row.item.id }}?</h5>
                            <b-row align-h="center">
                                <b-col cols="auto">
                                    <b-button type="submit" variant="danger" :disabled="$cannot('supplier.manage') || invalid"> Delete </b-button>
                                </b-col>
                            </b-row>
                        </form>
                    </validation-observer>
                </b-modal>
            </div>
        </template>
      </b-table>

      <!-- pagination -->
      <b-pagination
        v-model="meta.current_page"
        :total-rows="meta.total"
        :per-page="meta.per_page"
        @change="paginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import { products } from '@/api'
import CsvCodesBatchForm from './CSVCodesBatchForm'
import CsvCodesBatchEditForm from './CSVCodesBatchEditForm'
import { formatDate } from '@/pages/page-helpers'
import dayjs from 'dayjs'

export default {
  name: 'CSVCodes',
  inject: ['$cannot'],
  components: { CsvCodesBatchForm, CsvCodesBatchEditForm },
  data() {
    return { listing: [], meta: {}, variation_filter: '', expired: false, used: false }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        {
          key: 'product_id',
          label: 'Product ID',
          tdClass: 'test-class',
        },
        {
          key: 'supplier',
          label: 'Supplier',
          formatter: (value, key, item) => {
            return this.generateSupplier(item)
          },
        },
        {
          key: 'product_variation',
          label: 'Variation',
          formatter: (value, key, item) => {
            return this.generateVariationNames(item)
          },
        },
        {
          key: 'place',
          label: 'Place',
          formatter: (value, key, item) => {
            return this.generatePlaces(item)
          },
        },
        { key: 'num_codes', label: 'Codes' },
        { key: 'used_codes', label: 'Used' },
        { key: 'alert_when' },
        { key: 'valid_for' },
        { key: 'start_time', formatter: (date) => dayjs(date).format('DD.MM.YYYY HH:mm:ss') },
        { key: 'end_time', formatter: (date) => dayjs(date).format('DD.MM.YYYY HH:mm:ss') },
        { key: 'Actions' },
      ]
    },
  },

  beforeRouteEnter(from, to, next) {
    next((vm) => vm.loadContent())
  },

  methods: {
    async loadContent() {
      const page = this.$route.query.page ?? 1
      const response = await products.variations.batches.go(page, {
        filter: {
          variation_name: this.variation_filter,
          expired: this.expired,
          used: this.used,
        },
      })

      this.$nextTick(() => {
        this.listing = response.data().map((data) => {
          return {...data, product_id: data.product_variations.length > 0 ? data.product_variations[0].product_id : undefined}
        })

        this.meta = response.meta
      })
    },

    paginationChange(page) {
      this.$router.push(page === 1 ? { name: 'csvcodes' } : { name: 'csvcodes', query: { page } })

      this.loadContent()
    },

    refresh(callback) {
      this.loadContent()
      callback()
    },

    generateVariationNames(item) {
      let variation_names = ''
      item.product_variations.forEach(product_variation => {
        variation_names += product_variation['variation_name'] + '<br>'
      })
      return variation_names
    },

    generateSupplier(item) {
      let supplier = ''
      item.product_variations.forEach(product_variation => {
        if (product_variation.supplier['booking_name'] !== undefined) {
          supplier = product_variation.supplier['booking_name']
        }
      })
      return supplier
    },

    generatePlaces(item) {
      let places = ''
      item.product_variations.forEach(product_variation => {
        places += (product_variation.place['name'] ?? 'No place') + '<br>'
      })
      return places
    },

    async deleteBatch(id) {
        await products.variations.batches.destroy(id)

        this.loadContent()

        this.$bvToast.toast(`Batch deleted.`, {
            title: 'Batch deleted',
            variant: 'primary',
        })
        this.$bvModal.hide("deleteCSVCodeBatch-" + id)

        this.$emit('close')
    }
  },
}
</script>
