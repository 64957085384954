<template>
  <validation-observer v-slot="{ invalid }">
    <h5>Supplier: <br>{{ supplier.booking_name }}</h5>
    <hr>
    <h5>Product: <br>{{ product.name }}</h5>
    <form @submit.prevent="handleSubmit">
      <template v-if="product">
        <hr />
        <validation-provider name="Supplier" v-slot="context" rules="required">
          <multi-select
            v-model="selectedVariations"
            placeholder="Pick a variation"
            track-by="id"
            label="variation_name"
            :options="filteredVariations"
            :multiple="true"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            :custom-label="nameWithLang"
          />

          <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
            {{ context.errors[0] }}
          </small>
        </validation-provider>
        <hr />

        <b-row>
          <b-col>
            <validation-provider name="Start Time" v-slot="context" rules="required">
              <b-form-group label="Start Time">
                <b-form-datepicker
                  locale="nb-NO"
                  :start-weekday="1"
                  v-model="start_time"
                  :state="getValidationState(context)"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col>
            <validation-provider name="End Time" v-slot="context" rules="required">
              <b-form-group label="End Time">
                <b-form-datepicker
                  locale="nb-NO"
                  :start-weekday="1"
                  v-model="end_time"
                  :state="getValidationState(context)"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <validation-provider name="Valid For" v-slot="context" rules="required">
          <b-form-group label="Valid For (Participants)">
            <b-form-input
              v-model="valid_for"
              placeholder="e.g 1"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Alert When" v-slot="context" rules="required">
          <b-form-group label="Alert When (Remaining Codes)">
            <b-form-input
              v-model="alert_when"
              placeholder="e.g 1"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Codes" v-slot="context" rules="required">
          <b-form-group label="Codes">
            <b-textarea
              rows="10"
              v-model="codes"
              placeholder="e.g A 1212121212"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Booking CSV Info" v-slot="context" rules="required">
          <b-form-group label="CSV Booking Info">
            <froala v-model="booking_csv_info" />
          </b-form-group>
        </validation-provider>

        <b-row v-if="!batch.item.deleted_at" align-h="end">
          <b-col cols="auto">
            <b-button type="submit" variant="primary" :disabled="$cannot('supplier.manage') || invalid"> Update </b-button>
          </b-col>
        </b-row>
      </template>
    </form>
  </validation-observer>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { getValidationState } from '../page-helpers'
import { products } from '@/api'
import Froala from '@/components/Froala.vue'

export default {
  name: 'CSVCodesBatchEditForm',
  props: {batch: {type: Object}},
  inject: ['$cannot'],
  components: { Froala, MultiSelect },

  data() {
    return {
      booking_csv_info: this.batch.item.product_variations[0]?.booking_csv_info,
      selectedVariations: this.batch.item.product_variations,
      suppliers: [],
      product: this.batch.item.product_variations[0].product,
      supplier: this.batch.item.product_variations[0].supplier,

      codes: this.batch.item.raw_codes,
      alert_when: this.batch.item.alert_when,
      start_time: this.batch.item.start_time,
      end_time: this.batch.item.end_time,
      valid_for: this.batch.item.valid_for,
    }
  },

  computed: {
    filteredVariations() {
      if (!this.supplier) {
        return [];
      }

      return this.supplier.variations.filter((variation) => variation.product_id === this.product.id);
    }
  },

  methods: {
    getValidationState,

    nameWithLang({ variation_name, place }) {
      return place !== null ?
          `${variation_name}, ${place.name ?? place.city.name}` :
          `${variation_name}`
    },

    async handleSubmit() {
      await products.variations.batches.update(this.batch.item.id,
        {
          product_id: this.selectedVariations[0].product_id,
          variations: this.selectedVariations,
          codes: this.codes,
          alert_when: this.alert_when,
          start_time: this.start_time,
          end_time: this.end_time,
          valid_for: this.valid_for,
          booking_csv_info: this.booking_csv_info,
        }
      )

      this.$bvToast.toast(`Batch updated.`, {
        title: 'Batch updated',
        variant: 'primary',
      })

      this.$emit('close')
    },
  },
}
</script>
