<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="handleSubmit">
      <validation-provider name="Supplier" v-slot="context" rules="required">
        <multi-select
          v-model="supplier"
          placeholder="Pick a supplier"
          track-by="id"
          label="invoice_legal_name"
          :options="suppliers"
          :internal-search="false"
          @search-change="debouncedFunction"
          @input="product = null"
        />

        <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
          {{ context.errors[0] }}
        </small>
      </validation-provider>

      <template v-if="supplier">
        <hr />

        <validation-provider name="Product" v-slot="context" rules="required">
          <multi-select
            v-model="product"
            placeholder="Pick a product"
            :options="supplierProducts"
            track-by="id"
            label="name"
            @input="changedProduct"
          />

          <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
            {{ context.errors[0] }}
          </small>
        </validation-provider>
      </template>

      <template v-if="product">
        <hr />

        <validation-provider name="Supplier" v-slot="context" rules="required">
          <multi-select
            v-model="selectedVariations"
            placeholder="Pick a variation"
            @select="selectedProductVariation"
            track-by="id"
            label="variation_name"
            :options="filteredVariations"
            :multiple="true"
            :preserve-search="true"
            :close-on-select="false"
            :clear-on-select="false"
            :custom-label="nameWithLang"
          />

          <small v-if="context.errors.length" v-bind="context.ariaMsg" class="text-danger form-text">
            {{ context.errors[0] }}
          </small>
        </validation-provider>
        <hr />

        <b-row>
          <b-col>
            <validation-provider name="Start Time" v-slot="context" rules="required">
              <b-form-group label="Start Time">
                <b-form-datepicker
                  locale="nb-NO"
                  :start-weekday="1"
                  v-model="start_time"
                  :state="getValidationState(context)"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col>
            <validation-provider name="End Time" v-slot="context" rules="required">
              <b-form-group label="End Time">
                <b-form-datepicker
                  locale="nb-NO"
                  :start-weekday="1"
                  v-model="end_time"
                  :state="getValidationState(context)"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <validation-provider name="Valid For" v-slot="context" rules="required">
          <b-form-group label="Valid For (Participants)">
            <b-form-input
              v-model="valid_for"
              placeholder="e.g 1"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Alert When" v-slot="context" rules="required">
          <b-form-group label="Alert When (Remaining Codes)">
            <b-form-input
              v-model="alert_when"
              placeholder="e.g 1"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Codes" v-slot="context" rules="required">
          <b-form-group label="Codes">
            <b-textarea
              v-model="codes"
              placeholder="e.g A 1212121212"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Booking CSV Info" v-slot="context" rules="required">
          <b-form-group label="CSV Booking Info">
            <froala v-model="booking_csv_info" />
          </b-form-group>
        </validation-provider>

        <b-row align-h="end">
          <b-col cols="auto">
            <b-button type="submit" variant="primary" :disabled="$cannot('supplier.manage') || invalid"> Add </b-button>
          </b-col>
        </b-row>
      </template>
    </form>
  </validation-observer>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { getValidationState } from '../page-helpers'
import { products, suppliers } from '@/api'
import Froala from '@/components/Froala.vue'
import { debounce } from "ts-debounce";

export default {
  name: 'CSVCodesBatchForm',
  inject: ['$cannot'],
  components: { Froala, MultiSelect },

  beforeMount() {
    this.findSupplier('')
  },

  data() {
    return {
      booking_csv_info: null,
      selectedVariations: null,
      variations: [],
      suppliers: [],
      product: null,
      supplier: null,

      codes: '',
      alert_when: 1,
      start_time: '',
      end_time: '',
      valid_for: 1,

      debouncedFunction: debounce(this.findSupplier, 300)
    }
  },

  computed: {
    supplierProducts() {
      const uniqueProducts = [];

      if (this.supplier) {
        this.supplier.variations.map((variation) => {
          if (uniqueProducts.findIndex((product) => product.id === variation.product_id) === -1) {
            uniqueProducts.push(variation.product)
          }
        })
      }

      return uniqueProducts;
    },

    filteredVariations() {
      if (!this.supplier) {
        return [];
      }

      const filteredVariations = this.supplier.variations.filter((variation) => variation.product_id === this.product.id);

      if (filteredVariations.length === 1) {
          this.booking_csv_info = filteredVariations[0].booking_csv_info;
      }

      return filteredVariations;
    }
  },

  methods: {
    getValidationState,

    findSupplier(text) {
      suppliers.all({filter: {search: text}})
        .then((res) => {
          this.suppliers = res.data()
          this.$forceUpdate();
        })
    },

    nameWithLang({ variation_name, place }) {
      return place !== null ?
          `${variation_name}, ${place.city.name}` :
          `${variation_name}`
    },

    async handleSubmit() {
      await products.variations.batches.create({
          product_id: this.selectedVariations[0].product_id,
          variations: this.selectedVariations,
          codes: this.codes,
          alert_when: this.alert_when,
          start_time: this.start_time,
          end_time: this.end_time,
          valid_for: this.valid_for,
          booking_csv_info: this.booking_csv_info,
        }
      )

      this.$bvToast.toast(`Codes for supplier uploaded.`, {
        title: 'Code batch uploaded',
        variant: 'primary',
      })

      this.$emit('close')
    },

    changedProduct() {
      this.selectedVariations = [];
      this.booking_csv_info = '';

      if (this.filteredVariations.length === 1) {
        this.selectedVariations.push(this.filteredVariations[0]);
      }
    },

    selectedProductVariation(variation) {
      this.booking_csv_info = variation.booking_csv_info;
    }
  },
}
</script>
